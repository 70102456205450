@import "../../scss/design";

.internal-content {
    background-image: url("../../assets/benefits-path.svg");
    background-position: 0% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5.08vw 11.98vw;
    min-height: 34.11vw;

    .content-path {
        width: min(100, 80px);
        margin-left: -20px;
    }

    .content-img {
        width: 100%;
        box-shadow: 0px 20px 20px -10px rgba($color: $font-gray, $alpha: 0.5);
        margin-bottom: 30px;
    }
}

@import "../../scss/design";

.nav-brand {
    .nav-logo {
        width: 100%;
        min-width: 16.67vw;
        max-width: 16.67vw;

        @include breakpoint(sm-max) {
            min-width: 40vw;
        max-width: 40vw;
          }
    }

    
}

@import '../../scss/design';

.about-hero {
  width: 100%;
  min-height: 29.06vw;
  background-image: url('../../assets/home-hero-path.svg'),
    url('../../assets/privacy-notice-hero.jpg');
  background-position: -2% 50%, 50% 50%;
  background-repeat: no-repeat;
  background-size: 49.11vw auto, cover;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5.52vw 12.5vw;

  .cont-home-hero {
    color: $white;

    .p-gray {
      color: $gray;
      margin-bottom: 1.56vw;
    }

    .icon-arrow-btn {
      margin-left: 2.45vw;
    }
  }

  @include breakpoint(md-max) {
    background-size: cover, cover;
  }
}

.privacy-scroll {
    overflow: scroll;
    max-height: 800px;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 0px 3px 3px -3px rgba(0,0,0,0.08) inset;
    -webkit-box-shadow: 0px 3px 3px -3px rgba(0,0,0,0.08) inset;
    -moz-box-shadow: 0px 3px 3px -3px rgba(0,0,0,0.08) inset;

  .int-p {
    margin-bottom: 20px;
    line-height: 160%;
  }
}



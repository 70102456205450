@import '../../scss/design';


.add-report {
    display: flex !important;
    color: $white;
    background-color: $red;
    margin: 20px auto;

    &:hover {
      background-color: $font-dark;
    }
  }

  .form-title {
      margin-bottom: 30px;
      font-weight: 500;
  }

  .avatar-icon {
    background-color: $red-light !important;

    .cloud-icon {
      color: $red;
    }
  }

  .MuiDropzoneArea-icon {
    color: $red !important;
    transition: all ease .3s !important;

    &:hover {
        color: $p-dark !important;
    }
  }
  .MuiDropzoneArea-root {
      outline: none !important;
  }
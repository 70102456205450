@import "../../scss/design";

.flex-toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    

    .flex-menu-brand {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint(sm-max) {
            margin-bottom: 30px;
            width: 100%;
            justify-content: space-between;
          }
    }

    @include breakpoint(sm-max) {
        justify-content: center !important;
        align-items: center;
        margin-bottom: 30px;
        flex-wrap: wrap;
      }

      .top-link {
        @include breakpoint(sm-max) {
          width: 100%;
        display: flex;
        justify-content: center;
        }
        
      }
}

.MuiToolbar-root.MuiToolbar-regular.flex-toolbar.MuiToolbar-gutters {
  @include breakpoint(sm-max) {
    justify-content: center !important;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
}

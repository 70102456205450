@import '../../scss/design';

.inspectors-hero {
  width: 100%;
  min-height: 29.06vw;
  background-image: url('../../assets/home-hero-path.svg'),
    url('../../assets/inspectors-hero.jpg');
  background-position: -2% 50%, 50% 50%;
  background-repeat: no-repeat;
  background-size: 49.11vw auto, cover;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5.52vw 12.5vw;

  .cont-home-hero {
    color: $white;

    .p-gray {
      color: $gray;
      margin-bottom: 1.56vw;
    }

    .icon-arrow-btn {
      margin-left: 2.45vw;
    }
  }
  @include breakpoint(md-max) {
    background-size: cover, cover;
  }
}

.int-p {
  margin-bottom: 20px;
  line-height: 160%;
}

.last-p {
  margin-bottom: 30px;
  line-height: 160%;
}

.divider {
  margin: 50px 0px !important;
}

.h6-strong {
  font-weight: 500;
}

.list {
  background-color: $white;
  box-shadow: 0px 20px 20px -10px rgba($color: $font-gray, $alpha: 0.5);
  margin: 20px 0;

  .page-number {
    margin: 20px auto;
    text-align: center;
  }

  .inspection-link {
    color: $p-dark;
    transition: all ease 0.3s;

    &:hover {
      color: $red;
    }
  }

  .list-item {
    padding: 10px 20px;
    cursor: pointer;
    transition: all ease 0.3s;
    &:hover {
      background-color: #fcfcfc;
    }

    .avatar {
      background-color: $red-light;

      .folder-icon {
        color: $red;
      }
    }

    .delete-container {
      position: relative;
      z-index: 9999999;

      .delete-btn {
        margin-right: 0px !important;
      }
    }
  }

  .add-report {
    display: flex !important;
    color: $white;
    background-color: $red;
    margin: 20px auto;

    &:hover {
      background-color: $font-dark;
    }
  }
}

.MuiPagination-ul {
  justify-content: center;
  padding: 10px 0 !important;
}

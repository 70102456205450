@import '../../scss/design';

.blog-hero {
  width: 100%;
  min-height: 29.06vw;
  background-image: url('../../assets/home-hero-path.svg'),
    url('../../assets/blog-hero.jpg');
  background-position: -2% 50%, 50% 50%;
  background-repeat: no-repeat;
  background-size: 49.11vw auto, cover;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5.52vw 12.5vw;

  .cont-home-hero {
    color: $white;

    .p-gray {
      color: $gray;
      margin-bottom: 1.56vw;
    }

    .icon-arrow-btn {
      margin-left: 2.45vw;
    }
  }
  @include breakpoint(md-max) {
    background-size: cover, cover;
  }
}

.MuiPagination-ul {
  justify-content: center;
  padding: 10px 0 !important;
  margin-top: 50px !important;
}

.MuiButton-textPrimary {
  color: $red !important;
}

@import '../../scss/design';

.checkout-bg {
  background-image: url('../../assets/home-hero-path.svg'),
    url('../../assets/checkout-bg.jpg');
  background-repeat: no-repeat;
  background-size: 70.11vw auto, cover;
  background-position: -2% 50%, 50% 50%;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: $red !important;
}

.MuiButton-containedPrimary {
  background-color: $red !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $red !important;
}

.inline-link {
  color: $p-dark !important;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .file {
    background-color: $red-light;
    margin-bottom: 10px;

    .file-icon {
      color: $red;
    }
  }

  .p-strong {
    text-transform: none;
    font-weight: 400;
  }

  &:hover {
    text-decoration: none;
  }
}

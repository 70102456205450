@import '../../scss/design';

.feather-map-pin {
 z-index: -1 !important;
 cursor: pointer;
}

.popup {
    width: 200px;
    padding: 0.5rem;
    z-index: 999999 !important;
}

.mapboxgl-popup {
    z-index: 9;
}

.search-bar-title {
    font-weight: 600 !important;
}
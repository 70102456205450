@import '../../scss/design';

.topbar {
  background-image: url('../../assets/benefits-path.svg');
  background-repeat: no-repeat;
  background-size: 13vw auto;
  background-position: 0% 50%;
  width: 100%;
  background-color: $black-1C;
  box-shadow: 0px 5px 6px -6px rgba($color: $black-1C, $alpha: 0.7);
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 30px;

  a,
  button {
    color: $font-gray;
    transition: all ease 0.3s;
    &:hover {
      color: $white;
    }
  }

  .p-topbar {
    text-transform: uppercase;
    margin: 0 10px;
    font-size: 0.875rem;
  }

  @include breakpoint(sm-max) {
    background-size: 0vw auto;
  }
}

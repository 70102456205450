@import '../../scss/design';

.login {
  height: 100vh;

  .image {
    background-image: url('../../assets/homepage-hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .wrapper {
    box-shadow: -5px 0px 6px -6px rgba($color: $black-1C, $alpha: 0.7);
    //background-color: $black-23;
  }

  .redirect {
    color: $red !important;
    position: relative !important;

    &:hover {
      text-decoration: none !important;
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      display: block;
      margin-top: 0px;
      right: 0px;
      background: $red;
      transition: width 0.2s ease;
      -webkit-transition: width 0.2s ease;
    }

    &:hover:after {
      width: 100%;
      left: 0;
      background: $red;
    }
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: $border-color !important;
}

//.MuiInputBase-input {
//background-color: $black-23 !important;
//}

.MuiFormLabel-root {
  color: $p-dark !important;
}

.MuiCheckbox-root {
  color: $p-dark !important;
}

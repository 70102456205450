@import "../../scss/design";

.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 43.49vw;
  margin: 0 4.9vw;

  @include breakpoint(lg-max) {
    display: none;
  }

  .nav-link {
    position: relative;
    font-size: clamp(0.57em, 0.625vw, 0.67em);
    text-transform: uppercase;
    color: $nav-link;
    font-weight: 200;
    transition: all ease 0.3s;
    padding: 0 !important;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 1px;
      display: block;
      margin-top: 3px;
      right: 0px;
      background: $nav-link;
      transition: width 0.2s ease;
      -webkit-transition: width 0.2s ease;
    }

    &:hover:after {
      width: 100%;
      left: 0;
      background: $nav-link;
    }

    &.is-active {
      color: $red;
      font-weight: 300;
      text-shadow: 0px 0px 20px rgba($color: $red, $alpha: 0.5);
    }
  }
}

// * ====================== Variables ====================== * //

// Colors Palette
$red: #de2c2c;
$red-light: #ffefef;
$red-dark: #880000;
$white: #ffffff;
$black-1C: #1c1c1c;
$black-23: #232323;
$nav-link: #404143;
$gray: #acacac;
$font-dark: #404143;
$p-dark: #63717a;
$bg-F5: #f5f5f5;
$bg-F6: #f6f6f6;
$font-gray: #8e8e8e;
$border-color: #707070;

// Breakpoints
$desktop: 1920px;
$smallDesktop: 1680px;
$laptop: 1440px;
$ipad-pro: 1024px;
$ipad: 768px;
$phone: 375px;
$oldPhone: 320px;

// Mixin Breakpoints
@mixin breakpoint($point) {
  @if $point == xl-max {
    @media (max-width: 1680px) {
      @content;
    }
  } @else if $point == lg-max {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $point == md-max {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == sm-max {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == xs-max {
    @media (max-width: 375px) {
      @content;
    }
  }
}

// Placeholders
%center-element {
  margin-left: auto;
  margin-right: auto;
}

@mixin section-padding {
  @include breakpoint(lg-max) {
    padding: 5.52vw 9.5vw;
  }
  @include breakpoint(md-max) {
    padding: 5.52vw 12.5vw;
  }
  @include breakpoint(sm-max) {
    padding: 5.52vw 12.5vw;
  }
  @include breakpoint(xs-max) {
    padding: 5.52vw 12.5vw;
  }
}

// * ====================== Default ====================== * //
body {
  font-family: "Manrope", sans-serif;
  color: $p-dark;
  font-size: 18px;
  background-color: $white;
  padding: 0vw 1.97vw 1.56vw 1.97vw;
}

a {
  text-decoration: none;
  color: $red;
  transition: all ease .3s;

  &:hover {
    color: $red-dark;
  }
}

* {
  box-sizing: border-box;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.containerDiv {
  width: 100%;
  max-width: 90.42vw;
  min-height: 2.6vw;
  @extend %center-element;

  @include breakpoint(xl-max) {
    max-width: 87.42vw;
  }
  @include breakpoint(lg-max) {
    max-width: 90.42vw;
  }
  @include breakpoint(md-max) {
    max-width: 95.42vw;
  }
  @include breakpoint(sm-max) {
    max-width: 87.42vw;
  }
  @include breakpoint(xs-max) {
    max-width: 87.42vw;
  }
}

// * ====================== Typography ====================== * //

// Fonts
h1,
.h1 {
  font-family: "Manrope", sans-serif;
  font-size: clamp(3.35em, 3.33vw, 3.56em);
  line-height: 120%;
  font-weight: 300;
  margin: 10px 0;
}

h2,
.h2 {
  font-family: "Manrope", sans-serif;
  font-size: clamp(2.91em, 2.92vw, 3.11em);
  line-height: 120%;
  font-weight: 200;
  margin: 10px 0;
}

h3,
.h3 {
  font-family: "Manrope", sans-serif;
  font-size: clamp(1.8em, 1.875vw, 2em);
  line-height: 120%;
  font-weight: 200;
  margin: 10px 0;
}

h4,
.h4 {
  font-family: "Manrope", sans-serif;
  font-size: clamp(1.47em, 1.56vw, 1.67em);
  line-height: 120%;
  font-weight: 200;
  margin: 10px 0;
}

h5,
.h5 {
  font-family: "Manrope", sans-serif;
  font-size: clamp(1.13em, 1.25vw, 1.33em);
  line-height: 120%;
  font-weight: 200;
  text-transform: uppercase;
  margin: 10px 0;
}

h6,
.h6 {
  font-family: "Manrope", sans-serif;
  font-size: clamp(0.8em, 0.94vw, 1em);
  line-height: 120%;
  font-weight: 200;
  text-transform: uppercase;
  margin: 10px 0;
}

p,
.p,
li {
  font-family: "Manrope", sans-serif;
  font-size: clamp(0.8em, 0.94vw, 1em);
  line-height: 120%;
  font-weight: 200;
  margin: 10px 0;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit !important; 
}

// * ====================== Override Default Material UI ====================== * //

// Navbar
.MuiAppBar-colorPrimary {
  background-color: $white !important;
  padding: 1.88vw 0px !important;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}

@import '../../scss/design';

.contact {
  height: auto;
  min-height: 115vh;

  .image {
    background-image: url('../../assets/contact-content.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .wrapper {
    box-shadow: -5px 0px 6px -6px rgba($color: $black-1C, $alpha: 0.7);

    .int-p {
      margin-bottom: 20px;
      line-height: 160%;
    }
  }

  .input-icon {
    color: $p-dark;
  }

  .hidden {
    display: none;
  }

  .avatar {
    background-color: $red-light;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 60px;
    height: 60px;

    .contact-icon {
      color: $red;
    }
  }

  .p-paper {
    font-weight: 500;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: $border-color !important;
}

.MuiFormLabel-root {
  color: $p-dark !important;
}

.MuiCheckbox-root {
  color: $p-dark !important;
}

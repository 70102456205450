@import '../../scss/design';

.mobile-sidebar {
  display: none;

  @include breakpoint(lg-max) {
    display: block;
  }
}

.icon-red {
  color: $red !important;
}

.nav-link {
  position: relative;
  font-size: clamp(0.57em, 0.625vw, 0.67em);
  text-transform: uppercase;
  color: $nav-link;
  padding: 0 !important;

  &.is-active {
    color: $red;
  }
}

.MuiIconButton-root {
  color: $p-dark !important;
  margin-right: 30px !important;
}

.MuiTypography-body1 {
  font-size: clamp(1.13em, 1.25vw, 1.33em) !important;
  font-family: 'Manrope', sans-serif !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

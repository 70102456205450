@import '../../scss/design';

.primary-button {
  width: 100%;
  min-width: 250px !important;
  max-width: 15.31vw !important;
  min-height: 57px;
  line-height: 120%;
  padding: 0.73vw 2.08vw 0.83vw 2.08vw !important;
  background-color: $red !important;
  border-radius: 0px !important;
  color: $white !important;
  font-family: 'Manrope', sans-serif !important;
  font-size: clamp(0.91em, 1.04vw, 1.11em) !important;
  font-weight: 200 !important;
  text-transform: none !important;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease !important;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 3px 8px 0px rgba($color: $black-1C, $alpha: 0.22);
  }

  &:disabled {
    cursor: no-drop;
    opacity: 0.5;
  }

  &.button-large {
    max-width: 20.31vw !important;
  }

  &.button-secondary {
    background-color: transparent !important;
    color: $white !important;
    border: 1px solid $white;
    transition: all 0.3s ease !important;

    &:hover {
      transform: scale(1.03);
      box-shadow: 0px 3px 8px 0px rgba($color: #000000, $alpha: 0.22);
    }
  }

  &.button-transparent {
    background-color: transparent !important;
    border: none !important;
    color: $white !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.73vw 2.08vw 0.83vw 0vw !important;

    &:hover {
      transform: none;
      box-shadow: none;
      margin-left: 10px;
    }
  }

  &.button-submit {
    max-width: 100% !important;
    margin-top: 20px;
    margin-bottom: 50px;
    font-weight: 400 !important;

    &:hover {
      transform: none;
      background-color: $red-dark !important;
    }
  }
}

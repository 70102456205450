@import '../../scss/design';

.directory-hero {
  width: 100%;
  min-height: 29.06vw;
  background-image: url('../../assets/home-hero-path.svg'),
    url('../../assets/directory-hero.jpg');
  background-position: -2% 50%, 50% 50%;
  background-repeat: no-repeat;
  background-size: 49.11vw auto, cover;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5.52vw 12.5vw;

  .cont-home-hero {
    color: $white;

    .p-gray {
      color: $gray;
      margin-bottom: 1.56vw;
    }

    .icon-arrow-btn {
      margin-left: 2.45vw;
    }
  }
  @include breakpoint(md-max) {
    background-size: cover, cover;
  }
}

.internal-content-directory {
  background-image: url("../../assets/benefits-path.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5.08vw 11.98vw;
  min-height: 34.11vw;

  .content-path {
      width: min(100, 80px);
      margin-left: -20px;
  }

  .content-img {
      width: 100%;
      box-shadow: 0px 20px 20px -10px rgba($color: $font-gray, $alpha: 0.5);
      margin-bottom: 30px;
  }

  @include breakpoint(sm-max) {
    padding: 5.08vw 0.98vw;
  }
}

.h4-bio {
    margin-bottom: 30px;
}

.int-p {
  margin-bottom: 20px;
  line-height: 160%;
}

.last-p {
  margin-bottom: 30px;
  line-height: 160%;
}

.divider {
  margin: 50px 0px !important;
}

.h6-strong {
  font-weight: 500;
}

.list {
  background-color: $white;
  box-shadow: 0px 20px 20px -10px rgba($color: $font-gray, $alpha: 0.5);
  margin: 20px 0;

  .page-number {
    margin: 20px auto;
    text-align: center;
  }

  .inspection-link {
    color: $p-dark;
    transition: all ease 0.3s;

    &:hover {
      color: $red;
    }
  }

  .list-item {
    padding: 10px 20px;
    cursor: pointer;
    transition: all ease 0.3s;
    &:hover {
      background-color: #fcfcfc;
    }

    .avatar {
      background-color: $red-light;

      .folder-icon {
        color: $red;
      }
    }

    .delete-container {
      position: relative;
      z-index: 9999999;

      .delete-btn {
        margin-right: 0px !important;
      }
    }
  }

  .add-report {
    display: flex !important;
    color: $white;
    background-color: $red;
    margin: 20px auto;

    &:hover {
      background-color: $font-dark;
    }
  }
}

.search-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px 10px;

    .search-bar-title {
        margin: 0 20px !important;
        font-weight: 400;
        text-transform: none;
    }

    .input-search {
        margin-top: 0 !important;
        margin-left: 20px;
        margin-right: 20px;
    }
}

.email-link {
  margin-right: 5px;
}

.icon-button {
  
  &:hover {
    background-color: #fafafa !important;
  }

  .file-copy {
    color: $font-gray;
    font-size: 1rem;
  }
}


.MuiPagination-ul {
  justify-content: center;
  padding: 10px 0 !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.bio-container {
  padding: 50px;
  @include breakpoint(sm-max) {
    padding: 0px;
  }
}
@import "../../scss/design";

.footer {
  background-color: $black-1C;
  min-height: 31.46vw;
  padding: 5.05vw 12.14vw;

  @include section-padding;

  .footer-row1 {
    margin-bottom: 5vw;

    .h6-footer {
      text-transform: capitalize;
      color: $white;
      margin-bottom: 20px;
    }

    .p-small {
      font-size: clamp(0.68em, 0.83vw, 0.88em);
      color: $font-gray;

      .span-white {
        color: $white;
      }

      a {
        color: $font-gray;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 1px;
          display: block;
          margin-top: 0px;
          right: 0px;
          background: $font-gray;
          transition: width 0.2s ease;
          -webkit-transition: width 0.2s ease;
        }

        &:hover:after {
          width: 100%;
          left: 0;
          background: $font-gray;
        }
      }
    }
  }

  .footer-row2 {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    min-height: 3.65vw;
    padding: 25px 35px;
    margin-bottom: 3.9vw;

    a {
      color: $p-dark;
      transition: all ease 0.3s;

      &:hover {
        color: $white;
      }
    }

    .footer-link {
      text-transform: uppercase;
      font-size: clamp(0.57em, 0.625vw, 0.67em);
      font-weight: 200;
      padding: 0px 5px;
      margin: 10px 0px;
      overflow: hidden;
      span {
        width: auto;
        position: relative;
        display: inline-block;
        transition: all ease 0.3s;
        &::before {
          width: 4.69vw;
          color: white;
          position: absolute;
          top: 100%;
          content: attr(data-hover);
          font-weight: 300;
          transform: translate3d(0, 0, 0);
        }
      }
      &:hover {
        span {
          transform: translateY(-100%);
        }
      }

      &.is-active {
        font-weight: 300;
        color: $white;
      }
    }

    .sm-icon-link {
      margin-top: 10px;
    }
  }

  .footer-row3 {
    .p-small {
      text-align: center;
      a {
        color: $white;
        position: relative;
        transition: all ease 0.3s;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 1px;
          display: block;
          margin-top: 0px;
          right: 0px;
          background: $white;
          transition: width 0.2s ease;
          -webkit-transition: width 0.2s ease;
        }

        &:hover:after {
          width: 100%;
          left: 0;
          background: $white;
        }
      }
    }
  }
}

@import '../scss/design';

.makeStyles-root-15 {
  // ------- Dashboard ------- //
  .MuiAppBar-colorPrimary {
    padding: 0px !important;

    button {
      color: $white !important;
      transition: all ease 0.3s;
      &:hover {
        color: $white;
      }
    }
  }

  a {
    color: $red !important;
  }

  .MuiTypography-colorPrimary {
    color: $red !important;
  }

  .makeStyles-content-26 {
    path {
      stroke: $red !important;
    }
  }

  .dashboard-logo {
    width: 100%;
    max-width: 170px;
  }

  // ------- listItems ------- //
  .list-item {
    &:hover {
      background-color: $red-light !important;
    }

    .side-bar-icon {
      color: $red;
    }
  }

  .MuiListItemIcon-root {
    min-width: 50px !important;
  }

  @media (min-width: 600px) {
    .makeStyles-drawerPaperClose-24 {
      width: 56px;
    }
  }
}

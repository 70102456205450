@import '../../scss/design';

.inspection-item-container {
  padding: 50px 30px;

  .inspection-icon {
    margin: 0 auto 30px auto;
    background-color: $red-light;
    width: 100%;
    max-width: 80px;
    min-height: 80px;

    .desc-icon {
      color: $red;
      width: 100%;
      max-width: 30px;
      min-height: 30px;
    }
  }

  .item-title {
    margin: 20px auto 50px auto;
    text-align: center;

    .h5-strong {
      font-weight: 500;
      text-transform: none;
    }
  }
  .h6-strong-lower {
    text-transform: none;
    font-weight: 500;
    color: $font-dark;
  }
  .p-strong {
    text-transform: none;
    font-weight: 400;
  }

  .inline-link {
    color: $p-dark;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .file {
      background-color: $red-light;
      margin-bottom: 10px;

      .file-icon {
        color: $red;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  .p-last {
    margin-bottom: 60px;
  }

  .link-back {
    margin: 50px auto 0px auto;
    transition: all ease 0.3s;

    &:hover {
      background-color: $red-light;
      color: $red;
    }
  }
}

@import "../../scss/design";

.home-page {
  .hero {
    width: 100%;
    min-height: 49.06vw;
    background-image: url("../../assets/home-hero-path.svg"), url("../../assets/homepage-hero.jpg");
    background-position: -2% 50%, 50% 50%;
    background-repeat: no-repeat;
    background-size: 49.11vw auto, cover;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 5.52vw 12.5vw;

    @include section-padding;

    .img-red-check {
      position: absolute;
      top: 12.92vw;
      left: 32.25vw;
      width: 100%;
      max-width: 25.96vw;

      @include breakpoint(md-max) {
        max-width: 8.96vw;
      }
      @include breakpoint(sm-max) {
        max-width: 0vw;
      }
    }

    .cont-home-hero {
      color: $white;

      .p-gray {
        color: $gray;
        margin-bottom: 1.56vw;
      }

      .icon-arrow-btn {
        margin-left: 2.45vw;
      }
    }

    @include breakpoint(md-max) {
      background-size: cover, cover;
    }
    
  }

  .benefits {
    background-image: url("../../assets/benefits-path.svg");
    background-position: -40.5% 50%;
    background-repeat: no-repeat;
    background-size: 81.91vw auto;
    padding: 5.08vw 11.98vw;
    min-height: 34.11vw;

    @include section-padding;

    .benefit-path {
      width: 100%;
      max-width: 5.2vw;
      margin-left: -20px;
    }

    .h2-benefit {
      color: $nav-link;
      margin-bottom: 50px;
    }

    .benefit-box {
      margin-bottom: 2.21vw;

      .benefit-img {
        width: 100%;
        max-width: 3.3vw;

        @include breakpoint(md-max) {
          max-width: 6.3vw;
        }
        @include breakpoint(sm-max) {
          max-width: 8.3vw;
        }
      }
    }
  }

  .services {
    //background-image: url("../../assets/living-room-furniture.png");
    background-position: 0% 50%;
    background-repeat: no-repeat;
    min-height: 59.18vw;
    padding: 5.7vw 20.86vw;

    @include section-padding;

    @include breakpoint(lg-max) {
      background-size: 45% auto;
    }
    @include breakpoint(md-max) {
      //padding: 5.52vw 12.5vw;
    }
    @include breakpoint(sm-max) {
      //padding: 5.52vw 12.5vw;
    }
    @include breakpoint(xs-max) {
      //padding: 5.52vw 12.5vw;
    }

    .h3-services {
      margin-bottom: 2.6vw;
    }

    .p-services {
      margin-bottom: 3.75vw;
    }

    $bgImages: serv-card1, serv-card2, serv-card3, serv-card4, serv-card5;

    @each $bgImg in $bgImages {
      .service-card-#{$bgImg} {
        width: 100%;
        max-width: 324px;
        min-height: 313px;
        box-shadow: 0px 20px 20px -10px rgba($color: $font-gray, $alpha: 0.5);
        background-image: linear-gradient(#23232364, #23232364), url(../../assets/#{$bgImg}.png);

        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        position: relative;
        transition: background-image ease 0.3s;

        &:hover {
          background-image: linear-gradient(#23232344, #23232344), url(../../assets/#{$bgImg}.png);
        }

        .red-corner {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $red;

          .arrow-icon {
            font-size: 1.2em;
          }
        }
      }
    }
  }

  .inspections {
    min-height: 43.44vw;
    background-image: url("../../assets/already_inspected_icon_bluegrey.png"), linear-gradient($black-1C, $black-1C);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: $white;
    padding: 6.97vw 12.5vw 10.21vw 12.5vw;

    @include section-padding;

    .inspection-cards-wrapper {
      margin-top: 6.97vw;

      .inspection-card {
        width: 100%;
        max-width: 328px;
        min-height: 328px;
        max-height: 328px;
        border: 1px solid $border-color;
        padding: 2.19vw 1.67vw 2.44vw 1.67vw;

        img {
          margin-bottom: 20px;
        }

        h6 {
          margin-bottom: 20px;
        }

        .p-font-gray {
          color: $font-gray;
        }
      }
    }
  }

  .testimonials {
    padding: 8.59vw 16.875vw 6.77vw 16.875vw;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include breakpoint(lg-max) {
      padding: 5.52vw 9.5vw !important;
    }
    @include breakpoint(md-max) {
      padding: 5.52vw 7.5vw;
    }
    @include breakpoint(sm-max) {
      padding: 5.52vw 1.5vw;
    }
    @include breakpoint(xs-max) {
      padding: 5.52vw 1.5vw;
    }

    .h5-red {
      color: $red;
    }

    .client-name {
      text-transform: none;
    }

    @media (max-width: $laptop) {
      padding: 8.59vw 16.875vw 6.77vw 16.875vw;
    }
    @media (max-width: $ipad-pro) {
      padding: 6.59vw 14.875vw 4.77vw 14.875vw;
    }
    @media (max-width: $ipad) {
      padding: 4.59vw 12.875vw 2.77vw 12.875vw;
    }
    @media (max-width: $phone) {
      padding: 2.59vw 10.875vw 0.77vw 10.875vw;
    }
  }
}

@import '../../scss/design';

.not-found {
  width: 100%;
  min-height: 37vw;
  background-image: url('../../assets/404.jpg');
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: $white;
  text-align: center;

  .h1-404 {
    font-size: clamp(8.35em, 8.33vw, 8.56em);
    font-weight: 500;
    text-shadow: 3px 6px rgba($color: #000000, $alpha: 0.5);
  }

  .black-bg {
    padding: 10px;
    background-image: linear-gradient(
      to right,
      transparent,
      #000000,
      #000000,
      transparent
    );
  }

  .btns-404 {
    width: 100%;
    max-width: 32vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    a {
      color: $white;
    }
  }
}
